import { useEffect } from "react";
import "../css/auditionportal.css";
import AOS from "aos";
import "aos/dist/aos.css";

const featuredata = [
  {
    id: 1,
    name: "Core Cell",
    logo: "img/wdct.jpeg",
    social: "https://google.com",
    about: "Hey there this is cell info look up here",
  },
  {
    id: 2,
    name: "WDCT Cell",
    logo: "img/wdct.jpeg",
    social: "https://google.com",
    about: "Hey there this is cell info look up here",
  },
  {
    id: 3,
    name: "Robo Cell",
    logo: "img/wdct.jpeg",
    social: "https://google.com",
    about: "Hey there this is cell info look up here",
  },
  {
    id: 4,
    name: "E Cell",
    logo: "img/wdct.jpeg",
    social: "https://google.com",
    about: "Hey there this is cell info look up here",
  },
  {
    id: 5,
    name: "RnD Cell",
    logo: "img/wdct.jpeg",
    social: "https://google.com",
    about: "Hey there this is cell info look up here",
  },
];

const CellInfo = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <section className="cellinformation" id="cellinfo__section" style={{ overflow: 'hidden' }}>
      <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
        <div className="gpt3__whatgpt3-heading">
          <h1 className="gradient__text" data-aos="fade-up" data-aos-duration="">The Five Cells of CCA :</h1>
        </div>
        <div className="gpt3__whatgpt3-container">
          {/* {featuredata.map((index, item) => (
            <Feature title={item.name} key={index} text={item.about} />
          ))} */}
          <Feature
            title="CORE"
            text="Engaged throughout the year,
            working behind the scenes, the core cell excels in cooperating with the 
            other cells to get things done. Our aim is to carry out the smooth functioning of the club. Filled
            with well-disciplined people we try to get out the best from
            everyone indulged in the club's activities. We conduct various technical and
            managements workshops/events and arrange sponsorships and monetary aids for the club and
            the annual techno-management fest of NITD-'AAROHAN'"
            uri="img/cells/logo/core.png"
          />
          <Feature
            title="WDCT"
            text="A congregation of enthusiastic techno-crats, we manage the
            technical aspects of the club, to keep it up and running in the
            cyber front. From designing websites for fests to organising
            workshops for amateurs, we nurture the creative spurt in the club.
            This team is responsible for the development, design of the
            various websites associated with CCA, the Aarohan official website
            and various online publication and propaganda of the events of the
            club."
            uri="img/cells/logo/wdct.png"
          />
          <Feature
            title="E-CELL"
            text="We at Entrepreneurship and Innovation Cell, NIT Durgapur, are a
            bunch of people who believe in the potent power of imagination and
            dreams. We strive to nourish the spirit of entrepreneurship among
            our members from the student community and faculty, inspire and
            encourage them to take on entrepreneurial challenges, and assist
            them in their efforts to launch and run business. We will also try
            to foster technical innovation within our campus, and would help
            them in proper incubations of the same."
            uri="img/cells/logo/ecell.png"
          />
          <Feature
            title="ROBO"
            uri="img/cells/logo/robocell.png"
            text="Robocell, CCA is the society which promotes and deals with the science of robots in our college.
            We organize several workshops, competitions and events in Aarohan as well as otherwise.
            Robocell has a single goal: to popularize and spread awareness about Robotics.
            In the current era, where technology changes are rapid, it becomes imperative to stay updated.
            We aim to use robotics and our creativity to do something positive for our soceity and nation."
          />
          <Feature
            title="R&D"
            uri="img/cells/logo/rnd.png"
            text="Research and Development (R&D) flourishes where young
            minds and experienced faculties work synergistically. The cell administers 
            all the research Programs of the university by monitoring and coordinating 
            the research Programs. The Research and Development Cell aims to nurture
            research culture in the College by promoting research in newly
            emerging and challenging areas of Engineering, Technology, Science
            and Humanities."
          />
        </div>
      </div>
    </section>
  );
};

const Feature = ({ title, text, uri }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <div className="gpt3__features-container__feature">
        <div className="gpt3__features-container__feature-title" data-aos="fade-up">
          <div />
          <h1>
            {title}
            <img
              className="gpt3__features-container_feature-img_mobile_only"
              src={uri}
              alt={title}
              data-aos="fade-left"
            />
          </h1>
        </div>
        {/* <div className="gpt3__features-container_feature-image">

    </div> */}

        <div className="gpt3__features-container_feature-text" data-aos="fade-up">
          <p>{text}</p>
          <div className="gpt3__features-container_feature-img_desktop_only" data-aos="fade-left" data-aos-duration="600">
            <img src={uri} alt={title} />
          </div>
        </div>
      </div>
    </>
  );
};
export default CellInfo;
