import React, { Component } from "react";

import Back_Button from "../components/back_button";
import { withRouter } from 'react-router-dom';
import { Button, ButtonGroup } from 'react-bootstrap';
import disableScroll from "disable-scroll";
import Loader2 from "../components/loader2";
import "./css/rnd.css";
var OnImagesLoaded = require("react-on-images-loaded");

var imgPF = "./img/members/pre_final/";
var imgF = "./img/members/final_year/";

class rnd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      disp: "none",
      imgHeight: 255,
      imgWidth: 275,
      members: [
        {
          name: "DEBARPAN BANDYOPADHYAY",
          post: "HEAD, RnD CELL",
          imgLocation: imgF + "DEBARPAN_BANDYOPADHYAY.jpg",
        },
        {
          name: "SHUBHANG SRIVASTAV",
          post: "HEAD, RnD CELL",
          imgLocation: imgF + "SHUBHANG_SRIVASTAV.png",
        },
        {
          name: "GYANSHU GAURAV",
          post: "INNOVATION HEAD",
          imgLocation: imgF + "GYANSHU_GAURAV.png",
        },
        {
          name: "ADITYA CHAVHAN",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "ADITYA_CHAVHAN.jpg",
        },
        {
          name: "ANKIT PANDEY",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "ANKIT_PANDEY.jpg",
        },
        {
          name: "ARCHISHMAN CHAKRABARTY",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "ARCHISHMAN_CHAKRABARTY.jpg",
        },
        {
          name: "KAJAL KUMARI",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "KAJAL_KUMARI.png",
        },
        {
          name: "MRINMOY ADHIKARY",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "MRINMOY_ADHIKARY.png",
        },
        {
          name: "RITABRATA DAS",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "RITABRATA_DAS.png",
        },
        {
          name: "ABHILASH MAMIDI",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "ABHILASH_MAMIDI.jpg",
        },
        {
          name: "ANISHA KUMARI",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "ANISHA_KUMARI.jpg",
        },
        {
          name: "DHARMANA ESWAR SAI",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "DHARMANA_ESWAR_SAI.jpg",
        },
        {
          name: "DURBA SINHA",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "DURBA_SINHA.jpg",
        },
        {
          name: "KAUSTAV MAZUMDER",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "KAUSTAV_MAZUMDER.jpg",
        },
        {
          name: "MD.SALMAN ALI",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "MD_SALMAN_ALI.jpg",
        },
        {
          name: "PRITAM DAS",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "PRITAM_DAS.jpg ",
        },
        {
          name: "SAHIL YADAV",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "SAHIL_YADAV.jpg",
        },
        {
          name: "SAKSHI YADAV",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "SAKSHI_YADAV.jpg",
        },
        {
          name: "SANIKOMMU VITAL REDDY",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "SANIKOMMU_VITAL_REDDY.jpg",
        },
        {
          name: "SRI NIKETH",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "SRI_NIKETH.png",
        },
      ],
    };
    disableScroll.on();
  }

  handleImageLoaded() {
    this.setState({
      loading: false,
      disp: "block",
    });
    disableScroll.off();
  }

  nextPath(path) {
    this.props.history.push(path);
  }

  createList() {
    var i,
      j,
      k = 0,
      row = [],
      col = [];
    for (i = 0; i < Math.floor(this.state.members.length / 3); i++) {
      col.push(
        <div className="col-sm-3 col-md-offset-2">
          <div className="membercard">
            <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
              <figure>
                <img
                  src={this.state.members[k].imgLocation}
                  alt=""
                  style={{
                    height: this.state.imgHeight,
                    width: this.state.imgWidth,
                  }}
                />
                <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                  <path d="M 0,414 275,414 275,350 0,350 z" />
                </svg>
                <figcaption>
                  <h1>{this.state.members[k].name}</h1>
                  <p>{this.state.members[k].post}</p>
                </figcaption>
              </figure>
            </a>
          </div>
        </div>
      );
      k++;
      for (j = 0; j < 2; j++) {
        col.push(
          <div className="col-sm-3">
            <div className="membercard">
              <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
                <figure>
                  <img
                    src={this.state.members[k].imgLocation}
                    alt=""
                    style={{
                      height: this.state.imgHeight,
                      width: this.state.imgWidth,
                    }}
                  />
                  <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                    <path d="M 0,414 275,414 275,350 0,350 z" />
                  </svg>
                  <figcaption>
                    <h1>{this.state.members[k].name}</h1>
                    <p>{this.state.members[k].post}</p>
                  </figcaption>
                </figure>
              </a>
            </div>
          </div>
        );
        k++;
      }
      row.push(<div className="row no-gutter">{col}</div>);
      col = [];
    }

    if (this.state.members.length % 3 !== 0)
      col.push(
        <div className="col-sm-3 col-md-offset-2">
          <div className="membercard">
            <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
              <figure>
                <img
                  src={this.state.members[k].imgLocation}
                  alt=""
                  style={{
                    height: this.state.imgHeight,
                    width: this.state.imgWidth,
                  }}
                />
                <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                  <path d="M 0,414 275,414 275,350 0,350 z" />
                </svg>
                <figcaption>
                  <h1>{this.state.members[k].name}</h1>
                  <p>{this.state.members[k].post}</p>
                </figcaption>
              </figure>
            </a>
          </div>
        </div>
      );

    k++;
    for (i = 1; i < this.state.members.length % 3; i++) {
      col.push(
        <div className="col-sm-3">
          <div className="membercard">
            <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
              <figure>
                <img
                  src={this.state.members[k].imgLocation}
                  alt=""
                  style={{
                    height: this.state.imgHeight,
                    width: this.state.imgWidth,
                  }}
                />
                <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                  <path d="M 0,414 275,414 275,350 0,350 z" />
                </svg>
                <figcaption>
                  <h1>{this.state.members[k].name}</h1>
                  <p>{this.state.members[k].post}</p>
                </figcaption>
              </figure>
            </a>
          </div>
        </div>
      );
      k++;
    }
    row.push(<div className="row no-gutter">{col}</div>);
    col = [];

    return row;
  }

  render() {
    return (
      <OnImagesLoaded
        onLoaded={this.handleImageLoaded.bind(this)}
        timeout={200000}
        delay={2000}
      >
        {this.state.loading ? <Loader2 /> : null}

        <div className="landing" id="rndLanding">
          <div>
            <Back_Button />
            <Button className="cornerBtn" onClick={() => this.nextPath('/prnd')}>
              R&D Corner
            </Button>
            <div className="about_cell">
              <div className="about_back">
                <h1>Research & Development Cell</h1>
                <h3>
                  Welcome To The Research Developement and Innovation Cell Of
                  The Club
                </h3>
              </div>
            </div>
          </div>

          <div id="anim" className="cell_details">
            <h1>Cell details</h1>
            <p className="cellinfo">
              "If we knew what it was we were doing, it would not be called
              research, would it? "<br />- Albert Einstein
              <br />
            </p>
            <h1 style={{ marginTop: "10vh" }}></h1>
            <p className="cellinfo" style={{ fontSize: "2.5rem" }}>
              Write from the begining of the dark age alot of ground breaking
              discoveries were made by man. There is no field in which research
              is not done. Research and Development (R&D) flourishes where young
              minds and experienced faculties work synergistically. R&D Cell has
              been established to promote and monitor the research Programs of
              the institute. The cell administers all the research Programs of
              the university by monitoring and coordinating the research
              Programs.The Research and Development Cell aims to nurture
              research culture in the College by promoting research in newly
              emerging and challenging areas of Engineering, Technology, Science
              and Humanities. Faculty and students have been encouraged to be
              creative, since it is the creative aspect that induces newer
              thinking. Lectures and demonstration are made for students to
              kindle their spirit of creativity by our own faculty and other
              experts along with paper presentation and workshop.
            </p>

            <div className="container">
              <div className="heading faculty">
                <h1 style={{ color: "white" }}>Meet the Members</h1>
              </div>
              {this.createList()}
            </div>
          </div>
        </div>
      </OnImagesLoaded>
    );
  }
}

export default rnd;
