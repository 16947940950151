import React, { Component } from "react";
import "./css/memberSection.css";

// const members=[
//     {
//         "name":"abc",
//         "post":"Head",
//         "imgLocation":"..img/members/ddsd"
//     },
// ]
// post, name, imgLocation
var imgPF = "./img/members/pre_final/";
var imgF = "./img/members/final_year/";

// if (window.innerWidth > 800)
// {
//     var styleImg={
//         img:{
//             left:'-3.5vw',
//         }
//     }
//     console.log("^^^^^^^");
// }

export default class cellsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgHeight: 255,
      imgWidth: 275,
      members: [
        {
          name: "ROHAN AGARWAL",
          post: "CONVENER & HEAD",
          imgLocation: imgF + "ROHAN_AGARWAL.png",
        },
        {
          name: "NIKHIL SINGH",
          post: "GENERAL SECRETARY",
          imgLocation: imgF + "NIKHIL_SINGH.png",
        },
        {
          name: "KANISHKA CHAUHAN",
          post: "VICE PRESIDENT",
           imgLocation: imgF + "KANISHKA_CHAUHAN.png",
        },
        {
          name: "HARSHIT RAI",
          post: "TREASURER",
          imgLocation: imgF + "HARSHIT_RAI.jpg",
        },
        {
          name: "SOUMYADEEP GHOSH",
          post: "HEAD, CORPORATE COMMUNICATIONS",
          imgLocation: imgF + "SOUMYADEEP_GHOSH.png",
        },
        {
          name: "SHASWAT CHOUDHARI",
          post: "HEAD, CORPORATE COMMUNICATIONS",
          imgLocation: imgF + "SHASWAT_CHOUDHARI.png",
        },
        {
          name: "ADITYA NARAYAN DAS",
          post: "HEAD, OPERATIONS",
          imgLocation: imgF + "ADITYA_NARAYAN_DAS.png",
        },
        {
          name: "SANKHAJIT GHOSH",
          post: "HEAD, OPERATIONS",
          imgLocation: imgF + "SANKHAJIT_GHOSH.jpg",
        },
        {
          name: "SIDDHARTH SINGH",
          post: "HEAD, OPERATIONS",
          imgLocation: imgF + "SIDDHARTH_SINGH.jpg",
        },
        {
          name: "MAINAAK CHAKRABORTY",
          post: "HEAD, ATH",
          imgLocation: imgF + "MAINAAK_CHAKRABORTY.jpg",
        },
        {
          name: "SUDHENDER KADIAN",
          post: "HEAD, ATH",
          imgLocation: imgF + "SUDHENDER_KADIAN.png",
        },
        {
          name: "SUMAN KUMARI",
          post: "HEAD, PUBLICITY",
          imgLocation: imgF + "SUMAN_KUMARI.png",
        },
        {
          name: "RISHI MARODIA",
          post: "HEAD, WDCT",
          imgLocation: imgF + "RISHI_MARODIA.jpg",
        },
        {
          name: "SATYAJIT PAUL",
          post: "HEAD, WDCT",
          imgLocation: imgF + "SATYAJIT_PAUL.jpg",
        },
        {
          name: "ADITYA RAJ",
          post: "HEAD, E-CELL",
          imgLocation: imgF + "ADITYA_RAJ.jpg",
        },
        {
          name: "ROHAN SINGH YADAV",
          post: "HEAD, E-CELL",
          imgLocation: imgF + "ROHAN_SINGH_YADAV.png",
        },
        {
          name: "DEBARPAN BANDYOPADHYAY",
          post: "HEAD, RnD CELL",
          imgLocation: imgF + "DEBARPAN_BANDYOPADHYAY.jpg",
        },
        {
          name: "SHUBHANG SRIVASTAV",
          post: "HEAD, RnD CELL",
          imgLocation: imgF + "SHUBHANG_SRIVASTAV.png",
        },
        {
          name: "AHAM PATEL",
          post: "HEAD, ROBOCELL",
          imgLocation: imgF + "AHAM_PATEL.jpg",
        },
        {
          name: "NANDAN KUMAR SHAW",
          post: "HEAD, ROBOCELL",
          imgLocation: imgF + "NANDAN_KUMAR_SHAW.png",
        },
        {
          name: "GAYATRI AGARWAL",
          post: "HEAD, PUBLICATIONS",
          imgLocation: imgF + "GAYATRI_AGARWAL.jpg",
        },
        {
          name: "RAJAT AGARWAL",
          post: "HEAD, PUBLICITY",
          imgLocation: imgF + "RAJAT_AGARWAL.png",
        },
        {
          name: "DEVANSH GUPTA",
          post: "ROBOCON COORDINATOR",
          imgLocation: imgF + "DEVANSH_GUPTA.jpg",
        },
        {
          name: "SOUMYADEEP SEAL",
          post: "ROBOCON COORDINATOR",
          imgLocation: imgF + "SOUMYADEEP_SEAL.jpg",
        },
        {
          name: "AYAN BABU",
          post: "DEPUTY TREASURER",
          imgLocation: imgPF + "AYAN_BABU.jpg",
        },
        {
          name: "DIVYA SOREN",
          post: "INCUBATION COORDINATOR",
          imgLocation: imgF + "DIVYA_SOREN.jpg",    
        },
        {
          name: "GYANSHU GAURAV",
          post: "INNOVATION HEAD",
          imgLocation: imgF + "GYANSHU_GAURAV.png",
        },
        {
          name: "ABHISHEK KUMAR",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "ABHISHEK_KUMAR.jpg",
        },
        {
          name: "ADITYA CHAVHAN",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "ADITYA_CHAVHAN.jpg",
        },
        {
          name: "AMARDEEP RANJAN",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "AMARDEEP_RANJAN.jpg",
        },
        {
          name: "ANKIT PANDEY",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "ANKIT_PANDEY.jpg",
        },
        {
          name: "ANUSHKA MANDAL",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "ANUSHKA_MANDAL.png",
        },
        {
          name: "ARCHISHMAN CHAKRABARTY",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "ARCHISHMAN_CHAKRABARTY.jpg",
        },
        {
          name: "B VISHAL",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "B_VISHAL.png",
        },
        {           
          name: "CHINTALA JAGADISH",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "CHINTALA_JAGADISH.png",
        },
        {
          name: "DONA ROY",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "DONA_ROY.jpg",
        },
        {
          name: "ISHITHA KUMARI",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "ISHITHA_KUMARI.png",
        },
        {
          name: "KAJAL KUMARI",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "KAJAL_KUMARI.png",
        },
        {
          name: "MRINMOY ADHIKARY",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "MRINMOY_ADHIKARY.png",
        },
        {
          name: "NITHISH KALYAN",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "NITHISH_KALYAN.jpg",
        },
        {
          name: "PANKHURI KUMARI",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "PANKHURI_KUMARI.png",
        },
        {
          name: "RANBIR MARANDI",
          post: "EXCUTIVE MEMBER",
          imgLocation: imgF + "RANBIR_MARANDI.png",
        },
        {
          name: "RITABRATA DAS",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "RITABRATA_DAS.png",
        },
        {
          name: "ROHAN KUMAR",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "ROHAN_KUMAR.jpg",
        },
        {
          name: "SAYANTAN SAMANTA",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "SAYANTAN_SAMANTA.jpg",
        },
        {           
          name: "SHREYA DEY SARKAR",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "SHREYA_DEY_SARKAR.png",
        },
        {
          name: "SINDHU AREPALLI",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "SINDHU_AREPALLI.png",
        },
        {
          name: "SUSMIT GHOSH",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "SUSMIT_GHOSH.png",
        },
        {
          name: "VIDHI SRIVASTAVA",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "VIDHI_SRIVASTAVA.jpg",
        },
        {
          name: "YUVRAJ GAUTAM",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "YUVRAJ_GAUTAM.jpg",
        },
      ],
    };
  }
  createList() {
    var i,
      j,
      k = 0,
      row = [],
      col = [];
    for (i = 0; i < Math.floor(this.state.members.length / 5); i++) {
      col.push(
        <div class="col-sm-2 col-md-offset-1">
          <div class="membercard MemberMembercard">
            <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
              <figure>
                <img
                  src={this.state.members[k].imgLocation}
                  alt=""
                  style={{ width: this.state.imgWidth }}
                />
                <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                  <path d="M 0,414 275,414 275,350 0,350 z" />
                </svg>
                <figcaption>
                  <h1>{this.state.members[k].name}</h1>
                  <p>{this.state.members[k].post}</p>
                </figcaption>
              </figure>
            </a>
          </div>
        </div>
      );
      k++;
      for (j = 0; j < 4; j++) {
        col.push(
          <div class="col-sm-2">
            <div class="membercard MemberMembercard">
              <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
                <figure>
                  <img
                    src={this.state.members[k].imgLocation}
                    alt=""
                    style={{ width: this.state.imgWidth }}
                  />
                  <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                    <path d="M 0,414 275,414 275,350 0,350 z" />
                  </svg>
                  <figcaption>
                    <h1>{this.state.members[k].name}</h1>
                    <p>{this.state.members[k].post}</p>
                  </figcaption>
                </figure>
              </a>
            </div>
          </div>
        );
        k++;
      }
      row.push(<div class="row no-gutter">{col}</div>);
      col = [];
    }

    /* If the number of members is a multiple of 5 then remove this col.push part */
    if (this.state.members.length % 5 !== 0) {
      col.push(
        <div class="col-sm-2 col-md-offset-1">
          <div class="membercard MemberMembercard">
            <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
              <figure>
                <img
                  src={this.state.members[k].imgLocation}
                  alt=""
                  style={{ width: this.state.imgWidth }}
                />
                <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                  <path d="M 0,414 275,414 275,350 0,350 z" />
                </svg>
                <figcaption>
                  <h1>{this.state.members[k].name}</h1>
                  <p>{this.state.members[k].post}</p>
                </figcaption>
              </figure>
            </a>
          </div>
        </div>
      );
    }

    k++;
    for (i = 1; i < this.state.members.length % 5; i++) {
      col.push(
        <div class="col-sm-2">
          <div class="membercard MemberMembercard">
            <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
              <figure>
                <img
                  src={this.state.members[k].imgLocation}
                  alt=""
                  style={{ width: this.state.imgWidth }}
                />
                <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                  <path d="M 0,414 275,414 275,350 0,350 z" />
                </svg>
                <figcaption>
                  <h1>{this.state.members[k].name}</h1>
                  <p>{this.state.members[k].post}</p>
                </figcaption>
              </figure>
            </a>
          </div>
        </div>
      );
      k++;
    }
    row.push(<div class="row no-gutter">{col}</div>);
    col = [];

    return row;
  }

  render() {
    return (
      <div id="team" style={{ display: this.props.disp }}>
        < div class="container my-2" >
          <div class="heading faculty">
            <h1><center>Meet the Faculty Advisors</center></h1>
          </div>
          < div class="container" style={{ textAlign: "center" }}></div>
          <div class="row no-gutter d-flex justify-content-center">


            <div class=" team__container d-flex justify-content-center">

              <div class="membercard MemberMembercard" style={{ margin: '30px' }}>
                <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
                  <figure>
                    <img src="./img/prof/jb.png" style={{ left: "0" }} />
                    <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                      <path d="M 0,414 275,414 275,350 0,350 z" />
                    </svg>
                    <figcaption>
                      <h1 style={{ bottom: "-2px" }}>DR. JOYDEEP BANNERJEE</h1>
                      <p>FACULTY ADVISOR</p>
                    </figcaption>
                  </figure>
                </a>
              </div>
              <div class="membercard MemberMembercard" style={{ margin: '30px' }}>
                <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
                  <figure>
                    <img src="./img/prof/om.jpeg" style={{ left: "0" }} />
                    <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                      <path d="M 0,414 275,414 275,350 0,350 z" />
                    </svg>
                    <figcaption>
                      <h1 style={{ bottom: "-2px" }}>DR. OINDRILLA MUKHERJEE</h1>
                      <p>FACULTY ADVISOR</p>
                    </figcaption>
                  </figure>
                </a>
              </div>

              <div class="membercard MemberMembercard" style={{ margin: '30px' }}>
                <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
                  <figure>
                    <img src="./img/prof/Aniruddha-Bhattacharya-3.png" style={{objectFit:"cover"}} />
                    <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                      <path d="M 0,414 275,414 275,350 0,350 z" />
                    </svg>
                    <figcaption>
                      <h1 style={{ bottom: "-2px" }}>DR. ANIRUDDHA BHATTACHARYA</h1>
                      <p>FACULTY ADVISOR</p>
                    </figcaption>
                  </figure>
                </a>
              </div>
{/* 
     <div className="membercard MemberMembercard" style={{ margin: '30px' }}>
      <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
        
        <figure style={{
          position: 'relative',
          width: '100%',
          paddingTop: '133.33%'}} >
          <img 
            src="./img/prof/Aniruddha-Bhattacharya-3.png" 
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center'
            }} 
            alt="DR. ANIRUDDHA BHATTACHARYA"
          />
          <svg viewBox="0 0 275 414" preserveAspectRatio="none"  >
            <path d="M 0,414 275,414 275,350 0,350 z"   />
          </svg>
         
             <figcaption style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
             
             
            color: 'black',
            
          }}>
          
            <h1 style={{   bottom:"-31px" ,marginTop:"0px",marginBottom:"0px"  }}>DR. ANIRUDDHA BHATTACHARYA</h1>
            <p style={{bottom:"-85px"}}>FACULTY ADVISOR</p>
          </figcaption>
        </figure>
      </a>
    </div> */}

    {/* temp */}
     {/* <figure style={{
          position: 'relative',
          width: '100%',
          paddingTop: '133.33%',  
          overflow: 'hidden',
          margin: 0
        }}> */}

                

            </div>

          </div>


          <div class="container" style={{ marginTop: "-120px" }}>
            <div class="heading member">
              <h1 style={{ fontSize: 36 }}>Meet the Team</h1>
            </div>

            {this.createList()}
          </div>


        </div></div>
    );
  }
}
