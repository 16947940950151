import React, { Component } from "react";

import Back_Button from "../components/back_button";
import { withRouter } from 'react-router-dom';
import { Button, ButtonGroup } from 'react-bootstrap';
import "./css/core.css";
import "./css/all_cells_common.css";

import disableScroll from "disable-scroll";
import Loader2 from "../components/loader2";
var OnImagesLoaded = require("react-on-images-loaded");

var imgPF = "./img/members/pre_final/";
var imgF = "./img/members/final_year/";

class core extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      disp: "none",
      imgHeight: 255,
      imgWidth: 275,
      members: [
        {
          name: "ROHAN AGARWAL",
          post: "CONVENER & HEAD",
          imgLocation: imgF + "ROHAN_AGARWAL.png",
        },
        {
          name: "NIKHIL SINGH",
          post: "GENERAL SECRETARY",
          imgLocation: imgF + "NIKHIL_SINGH.png",
        },
        {
          name: "KANISHKA CHAUHAN",
          post: "VICE PRESIDENT",
           imgLocation: imgF + "KANISHKA_CHAUHAN.png",
        },
        {
          name: "HARSHIT RAI",
          post: "TREASURER",
          imgLocation: imgF + "HARSHIT_RAI.jpg",
        },
        {
          name: "SOUMYADEEP GHOSH",
          post: "HEAD, CORPORATE COMMUNICATIONS",
          imgLocation: imgF + "SOUMYADEEP_GHOSH.png",
        },
        {
          name: "SHASWAT CHOUDHARI",
          post: "HEAD, CORPORATE COMMUNICATIONS",
          imgLocation: imgF + "SHASWAT_CHOUDHARI.png",
        },
        {
          name: "ADITYA NARAYAN DAS",
          post: "HEAD, OPERATIONS",
          imgLocation: imgF + "ADITYA_NARAYAN_DAS.png",
        },
        {
          name: "SANKHAJIT GHOSH",
          post: "HEAD, OPERATIONS",
          imgLocation: imgF + "SANKHAJIT_GHOSH.jpg",
        },
        {
          name: "SIDDHARTH SINGH",
          post: "HEAD, OPERATIONS",
          imgLocation: imgF + "SIDDHARTH_SINGH.jpg",
        },
        {
          name: "MAINAAK CHAKRABORTY",
          post: "HEAD, ATH",
          imgLocation: imgF + "MAINAAK_CHAKRABORTY.jpg",
        },
        {
          name: "SUDHENDER KADIAN",
          post: "HEAD, ATH",
          imgLocation: imgF + "SUDHENDER_KADIAN.png",
        },
        {
          name: "SUMAN KUMARI",
          post: "HEAD, PUBLICITY",
          imgLocation: imgF + "SUMAN_KUMARI.png",
        },
        {
          name: "AYAN BABU",
          post: "DEPUTY TREASURER",
          imgLocation: imgPF + "AYAN_BABU.jpg",
        },
        {
          name: "ANIRUDH SHARMA",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "ANIRUDH_SHARMA.jpg",
        },
        {
          name: "ANISH KALITA",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "ANISH_KALITA.jpg",
        },
        {
          name: "AVI BHATTACHARYA",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "AVI_BHATTACHARYA.jpg",
        },
        {
          name: "DASARI VENKATA PRASANNA KUMAR",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "DASARI_VENKATA_PRASANNA_KUMAR.jpg",
        },
        {
          name: "DEBADITYA ROY",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "DEBADITYA_ROY.png",
        },
        {
          name: "KHUSHI GILL",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "KHUSHI_GILL.jpg",
        },
        {
          name: "MANYA_MRIDUL",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "MANYA_MRIDUL.jpg",
        },
        {
          name: "RISHIKESH SHAW",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "RISHIKESH_SHAW.png",
        },
        {
          name: "SESHADRI SHREYANSH SRINIDHI",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "SESHADRI_SHREYANSH_SRINIDHI.jpg",
        },
        {
          name: "VEDANTH KUMAR",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "VEDANTH_KUMAR.png",
        },
      ],
    };
    disableScroll.on();
  }


  handleImageLoaded() {
    this.setState({
      loading: false,
      disp: "block",
    });
    disableScroll.off();
  }

  nextPath(path) {
    this.props.history.push(path);
  }

  createList() {
    var i,
      j,
      k = 0,
      row = [],
      col = [];
    for (i = 0; i < Math.floor(this.state.members.length / 3); i++) {
      col.push(
        <div className="col-lg-3 col-lg-offset-2  col-sm-6">
          <div className="membercard">
            <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
              <figure>
                <img
                  src={this.state.members[k].imgLocation}
                  alt=""
                  style={{
                    height: this.state.imgHeight,
                    width: this.state.imgWidth,
                  }}
                />
                <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                  <path d="M 0,414 275,414 275,350 0,350 z" />
                </svg>
                <figcaption>
                  <h1>{this.state.members[k].name}</h1>
                  <p>{this.state.members[k].post}</p>
                </figcaption>
              </figure>
            </a>
          </div>
        </div>
      );
      k++;
      for (j = 0; j < 2; j++) {
        col.push(
          <div className="col-lg-3 col-sm-6">
            <div className="membercard">
              <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
                <figure>
                  <img
                    src={this.state.members[k].imgLocation}
                    alt=""
                    style={{
                      height: this.state.imgHeight,
                      width: this.state.imgWidth,
                    }}
                  />
                  <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                    <path d="M 0,414 275,414 275,350 0,350 z" />
                  </svg>
                  <figcaption>
                    <h1>{this.state.members[k].name}</h1>
                    <p>{this.state.members[k].post}</p>
                  </figcaption>
                </figure>
              </a>
            </div>
          </div>
        );
        k++;
      }
      row.push(<div className="row no-gutter">{col}</div>);
      col = [];
    }

    /* IF the number of members is a multiple of 3 comment out this col.push part otherwise keep it */
    if (this.state.members.length % 3 !== 0) {
      col.push(
        <div className="col-lg-3 col-lg-offset-2 col-sm-6">
          <div className="membercard">
            <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
              <figure>
                <img
                  src={this.state.members[k].imgLocation}
                  alt=""
                  style={{
                    height: this.state.imgHeight,
                    width: this.state.imgWidth,
                  }}
                />
                <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                  <path d="M 0,414 275,414 275,350 0,350 z" />
                </svg>
                <figcaption>
                  <h1>{this.state.members[k].name}</h1>
                  <p>{this.state.members[k].post}</p>
                </figcaption>
              </figure>
            </a>
          </div>
        </div>
      );
    }

    k++;
    for (i = 1; i < this.state.members.length % 3; i++) {
      col.push(
        <div className="col-lg-3  col-sm-6">
          <div className="membercard">
            <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
              <figure>
                <img
                  src={this.state.members[k].imgLocation}
                  alt=""
                  style={{
                    height: this.state.imgHeight,
                    width: this.state.imgWidth,
                  }}
                />
                <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                  <path d="M 0,414 275,414 275,350 0,350 z" />
                </svg>
                <figcaption>
                  <h1>{this.state.members[k].name}</h1>
                  <p>{this.state.members[k].post}</p>
                </figcaption>
              </figure>
            </a>
          </div>
        </div>
      );
      k++;
    }
    row.push(<div className="row no-gutter">{col}</div>);
    col = [];

    return row;
  }

  render() {
    return (
      <OnImagesLoaded
        onLoaded={this.handleImageLoaded.bind(this)}
        timeout={200000}
        delay={2000}
      >
        {this.state.loading ? <Loader2 /> : null}
        <div className="landing" id="coreLanding">
          <div>
            <Back_Button />
            <Button  className="cornerBtn" onClick={() => this.nextPath('/pcore')}>
              Core Corner
            </Button>
            <div className="about_cell">
              <div className="about_back">
                <h1>Core Cell </h1>
                <h3>Welcome To The Core Cell Of The Club</h3>
              </div>
            </div>
          </div>
          <div id="anim" className="cell_details">
            <h1>Cell details</h1>
            <p className="cellinfo">
              "Management is doing things right; Leadership is doing right
              things."- Peter Drucker Motivating, along with leading is a task
              well known by these robust people. Engaged throughout the year,
              working behind the scenes,the core cell excels in cooperating with
              the other cells to get things done.
            </p>
            <h1 style={{ marginTop: "10vh" }}>Aim</h1>
            <p className="cellinfo" style={{ fontSize: "2.5rem" }}>
              Our aim is to carry out the smooth functioning of the club. Filled
              with well-disciplined people we try to get out the best from
              everyone indulged in the club's activities.
            </p>
            <h1>RESPONSIBILITIES</h1>
            <p className="cellinfo" style={{ fontSize: "2.5rem" }}>
              1.Overseeing the activities of the club.
              <br />
              2.Arranging as well as conducting various technical and
              managements workshops/events round the clock throughout the year
              inside and outside the college.
              <br />
              3.Arranging for sponsorships and monetary aids for the club and
              the annual techno-management fest of NITD-'AAROHAN'.
              <br />
              4.Publicising our events.
            </p>

            <div className="container">
              <div className="heading faculty">
                <h1 style={{ color: "white" }}>Meet the Members</h1>
              </div>
            </div>
            {this.createList()}
          </div>
        </div>
      </OnImagesLoaded>
    );
  }
}

export default core;
